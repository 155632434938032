// extracted by mini-css-extract-plugin
export var HomeAtvatarimage = "buyerdetail-module--HomeAtvatarimage--dc886";
export var LisAvatImage = "buyerdetail-module--LisAvatImage--50560";
export var ListingdetailhomeImage = "buyerdetail-module--ListingdetailhomeImage--8f578";
export var ManageTourDiv = "buyerdetail-module--ManageTourDiv--b2432";
export var ModifyButton = "buyerdetail-module--ModifyButton--d4b9b";
export var SellerDetailAddress = "buyerdetail-module--SellerDetailAddress--e64cb";
export var SellertourDetailThumbnailImages = "buyerdetail-module--SellertourDetailThumbnailImages--3e2f8";
export var TourAddressAvatarimages = "buyerdetail-module--TourAddressAvatarimages--d5a6a";
export var TourBedBath = "buyerdetail-module--TourBedBath--b0fa3";
export var addresslist = "buyerdetail-module--addresslist--e9283";
export var buyerAvatarImage = "buyerdetail-module--buyerAvatarImage--81940";
export var buyerImage = "buyerdetail-module--buyerImage--b30fc";
export var cardRowSpan = "buyerdetail-module--cardRowSpan--59bb4";
export var closed = "buyerdetail-module--closed--28281";
export var detaName = "buyerdetail-module--detaName--dce2b";
export var detailCardBack = "buyerdetail-module--detailCardBack--b43ce";
export var homeImage = "buyerdetail-module--homeImage--ecf0d";
export var homeprice = "buyerdetail-module--homeprice--3dcf0";
export var hrtag = "buyerdetail-module--hrtag--489b6";
export var listagent = "buyerdetail-module--listagent--6c306";
export var listbuyername = "buyerdetail-module--listbuyername--988bd";
export var listdate = "buyerdetail-module--listdate--5588c";
export var listingdate = "buyerdetail-module--listingdate--369c0";
export var listmodifybut = "buyerdetail-module--listmodifybut--6f27d";
export var listmodifybut1 = "buyerdetail-module--listmodifybut1--23682";
export var listtime = "buyerdetail-module--listtime--8d9c5";
export var manageTour = "buyerdetail-module--manageTour--89a96";
export var nameDetail = "buyerdetail-module--nameDetail--56a35";
export var opened = "buyerdetail-module--opened--89686";
export var pricedetail = "buyerdetail-module--pricedetail--3207d";
export var profileAvatarimage = "buyerdetail-module--profileAvatarimage--dbdad";
export var profileImage1 = "buyerdetail-module--profileImage1--2fc5d";
export var profileImage2 = "buyerdetail-module--profileImage2--ba0db";
export var propdetail = "buyerdetail-module--propdetail--eff14";
export var propdetail1 = "buyerdetail-module--propdetail1--45e7d";
export var propdetail2 = "buyerdetail-module--propdetail2--e9a4b";
export var propdetail3 = "buyerdetail-module--propdetail3--ceeaf";
export var properties = "buyerdetail-module--properties--6532f";
export var recenttourImage = "buyerdetail-module--recenttourImage--c1662";
export var recenttourImage1 = "buyerdetail-module--recenttourImage1--d66e9";
export var recenttourdetail1 = "buyerdetail-module--recenttourdetail1--3fd14";
export var recenttourdetail2 = "buyerdetail-module--recenttourdetail2--38d7b";
export var roomCount = "buyerdetail-module--roomCount--d672f";
export var saradetails = "buyerdetail-module--saradetails--c0960";
export var sarahImage = "buyerdetail-module--sarahImage--2064b";
export var score2 = "buyerdetail-module--score2--e2059";
export var sellerAvatarCard = "buyerdetail-module--sellerAvatarCard--b4571";
export var sellerDetailAddress = "buyerdetail-module--sellerDetailAddress--f8bcd";
export var sellerDetailAddress1 = "buyerdetail-module--sellerDetailAddress1--bf74e";
export var sellerDetailImg = "buyerdetail-module--sellerDetailImg--96814";
export var sellerDetailName = "buyerdetail-module--sellerDetailName--ea880";
export var sellerDetailName1 = "buyerdetail-module--sellerDetailName1--eda22";
export var sellerdetails = "buyerdetail-module--sellerdetails--572ef";
export var sellerimage = "buyerdetail-module--sellerimage--9f05d";
export var sellertour = "buyerdetail-module--sellertour--32e03";
export var sellertourAddressPrice = "buyerdetail-module--sellertourAddressPrice--ba80b";
export var sellertouraddress = "buyerdetail-module--sellertouraddress--271a8";
export var sellertourbeds = "buyerdetail-module--sellertourbeds--15cdd";
export var sellertourimage = "buyerdetail-module--sellertourimage--ff2d2";
export var sellertourtextAddress = "buyerdetail-module--sellertourtextAddress--bff16";
export var touraddress = "buyerdetail-module--touraddress--84a91";
export var touravailable = "buyerdetail-module--touravailable--ba103";
export var tourdetailcard = "buyerdetail-module--tourdetailcard--9577e";
export var useName = "buyerdetail-module--useName--47101";